import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage'; // Asegúrate de que la ruta sea correcta
import './App.css';
import "./i18n.js"
import NotFoundPage from './pages/NotFoundPage.js';
import HubspotMeetingPage from './pages/HubspotMeetingPage.js';
import PricingPage from './pages/PricingPage.js';
import SupportPage from './pages/SupportPage.js';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
//import ForClubsPage from './pages/ForClubsPage.js';
import Blog from './blog/Blog.js';
import BlogPost from './blog/BlogPost.js';

function App() {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="" element={<><Helmet><title>{t('Helmet00')}</title></Helmet><LandingPage /></>} />
            <Route path="/pricing" element={<><Helmet><title>{t('Helmet01')}</title></Helmet><PricingPage /></>} />
            {/* <Route path="/clubs" element={<><Helmet><title>{t('Helmet05')}</title></Helmet><ForClubsPage /></>} /> */}
            <Route path="/meeting" element={<><Helmet><title>{t('Helmet02')}</title></Helmet><HubspotMeetingPage /></>} />
            <Route path="/support" element={<><Helmet><title>{t('Helmet04')}</title></Helmet><SupportPage /></>} />


            {/* <Route exact path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} /> */}

            <Route path="*" element={<><Helmet><title>{t('Helmet03')}</title></Helmet><NotFoundPage /></>} /> {/* Ruta para manejar páginas no encontradas */}

          </Routes>
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;