import * as React from 'react';
import Layout from '../components/Layout';
import { IframeSupport } from '../components/IframeSupport';

export default function SupportPage() {
  return (
    <Layout>
      <IframeSupport />
    </Layout>
  );
}