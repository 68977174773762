import React from 'react';
import { Box } from '@mui/material';
import { COLORS } from '../constants/constants';
import Footer from './Footer';
import Header from './Header';
import BannerApp from './BannerApp';
import { Divider } from '@mui/material';

const Layout = ({ children }) => {
    return (
        <Box sx={{
            width: '100%',
            minHeight: '100vh',
            //backgroundImage: 'url("/images/background-landing-page.svg")',
            backgroundSize: '200px 200px', // Especifica un tamaño fijo para la imagen
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top right', // Posiciona la imagen en la esquina superior derecha
            backgroundColor: COLORS.superdarkblueICB
        }}>
            <Header />
            <BannerApp />
            {children}
            <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', paddingTop: 2 }} />
            <Footer />
            {/* <NewFooter /> */}
        </Box>
    );
}

export default Layout;