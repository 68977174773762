import React from 'react';
import { Grid, Typography, Grow, Slide } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useState, useEffect } from 'react';
import StartFreeTrialButton from './StartFreeTrialButton';

export default function Intro() {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });

    const [buttonVisible, setButtonVisible] = useState(false);

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                setButtonVisible(true);
            }, 1000); // Espera 1000ms después de que inView sea true
        }
    }, [inView]);


    return (
        <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '30px', md: '70px' }, paddingBottom: 10 }}>
            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '32px', md: '60px' },
                        color: '#dce4ec',
                        fontWeight: '900',
                        fontFamily: 'Roboto, sans-serif',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('Intro00')}
                    </Typography>
                </Grid>
            </Grow>

            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '20px', md: '25px' },
                        marginTop: '20px',
                        color: '#98a7b0',
                        fontFamily: 'Arial',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('Intro01')}
                    </Typography>
                </Grid>
            </Grow>

            <Slide in={inView} direction="left" timeout={3000}>
                <Grid container justifyContent="center" mt={5} ref={ref}>
                    <StartFreeTrialButton buttonVisible={buttonVisible} />
                </Grid>
            </Slide>

            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={3000}>
                <Grid container justifyContent="center" mt={5} ref={ref} sx={{ position: 'relative' }}>
                    <img src={t('Intro02')} alt="Static Background" style={{ width: '90%', height: 'auto', zIndex: 1 }} />
                </Grid>
            </Grow>
        </Grid>
    );
};