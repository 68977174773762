import React from 'react';
import Typography from '@mui/material/Typography';
import { COLORS, PRICES, appSignUp } from '../constants/constants';
import Slider from '@mui/material/Slider';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Divider } from '@mui/material';
import CheckRounded from '@mui/icons-material/CheckRounded';
import { Button, Grow, Box, lighten, darken } from '@mui/material';
import { useInView } from 'react-intersection-observer';

export default function PricingCards() {
    const { t } = useTranslation()

    const handleSliderChange = (index, newValue) => {
        const updatedOptions = optionsData.map((option, i) =>
            i === index ? { ...option, sliderValue: newValue } : option
        );
        setOptionsData(updatedOptions);
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });


    const [optionsData, setOptionsData] = React.useState([
        {
            title: t('PricingCards00'),
            description: t('PricingCards01'),
            sliderValue: 1, // Add this field
            bullets: [
                t('PricingCards02'),
                t('PricingCards03'),
                t('PricingCards04'),

            ]
        },
        {
            title: t('PricingCards05'),
            description: t('PricingCards06'),
            sliderValue: 1, // Add this field
            bullets: [
                t('PricingCards07'),
                t('PricingCards08'),
                t('PricingCards09'),
                t('PricingCards10'),
                t('PricingCards11'),
            ]
        },
        {
            title: t('PricingCards12'),
            description: t('PricingCards13'),
            sliderValue: 10, // Add this field
            bullets: [
                t('PricingCards14'),
                t('PricingCards15'),
                t('PricingCards16'),
                t('PricingCards17'),
                t('PricingCards18'),
                t('PricingCards19'),
                t('PricingCards20'),
            ]
        },
    ]);

    return (
        <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '20px', md: '100px' }, paddingBottom: 10 }}>

            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '25px', md: '40px' },
                        color: '#dce4ec',
                        fontWeight: '900',
                        fontFamily: 'Roboto, sans-serif',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('PricingCards22')}
                    </Typography>
                </Grid>
            </Grow>

            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '20px', md: '25px' },
                        marginTop: '20px',
                        color: '#98a7b0',
                        fontFamily: 'Arial',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('PricingCards23')}
                    </Typography>

                </Grid>
            </Grow>

            <Grow in={inView} style={{ transformOrigin: '0 100 0' }} timeout={2000}>
                <Grid container spacing={2} marginTop={5} sx={{ display: 'flex', alignItems: 'stretch' }}>
                    {optionsData.map((option, index) => (
                        <Grid item xs={12} sm={4} key={`grid-${index}`} sx={{ display: 'flex' }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                width: '100%', // Asegura que la caja ocupe todo el espacio disponible en su contenedor Grid
                                borderRadius: 1,
                                padding: 2,
                                backgroundColor: COLORS.blueICB,
                                border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`,
                            }}
                                key={`card-${index}`}
                            >

                                <div> {/* Contenedor para el contenido superior */}
                                    <Typography sx={{ fontWeight: 'bold', fontFamily: 'Roboto, sans-serif', textAlign: 'left', fontSize: { xs: '17px', sm: '25px' } }}>
                                        <span style={{ color: '#dce4ec' }}>{option.title}</span>
                                    </Typography>

                                    <Grid container justifyContent="left" alignItems="center" sx={{ flexDirection: 'row' }}>
                                        <Typography
                                            component="span"
                                            color={COLORS.orangeICB}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: {
                                                    xs: '19px',
                                                    sm: '30px',
                                                },
                                            }}
                                        >
                                            {index === 0 ? `${(PRICES.free)}€` :
                                                index === 1 ? `${(PRICES.proCoach)}€` :
                                                    `${(PRICES.proClub * option.sliderValue).toFixed(2)}€`}
                                        </Typography>

                                        <Typography
                                            sx={{
                                                color: '#98a7b0',
                                                fontFamily: 'Arial',
                                                textAlign: 'center',
                                                fontSize: {
                                                    xs: '12px',
                                                    sm: '15px'
                                                },
                                                marginLeft: 1 // Añade un pequeño margen para separar el precio de la descripción
                                            }}
                                        >
                                            {`${option.description}`}
                                        </Typography>
                                    </Grid>

                                    {index === 2 && (
                                        <>
                                            <Slider
                                                value={option.sliderValue}
                                                min={1}
                                                max={21}
                                                step={1}
                                                onChange={(e, newValue) => handleSliderChange(index, newValue)}
                                                aria-labelledby="input-slider"
                                                sx={{ margin: 'auto' }}
                                            />
                                            <Typography
                                                sx={{
                                                    color: COLORS.orangeICB,
                                                    fontWeight: 'bold',
                                                    fontSize: '18px',
                                                    marginRight: 0.5,
                                                    marginBottom: 1,
                                                    textAlign: 'left'  // Alinea el texto a la izquierda
                                                }}
                                            >
                                                {`${option.sliderValue - 1} ${t('PricingCards21')}`}
                                            </Typography>

                                        </>
                                    )}

                                    {(index === 0 || index === 1) && <Divider style={{ marginTop: 17, backgroundColor: '#98a7b0', marginBottom: 20 }} />}

                                    {option.bullets.map((bullet, bulletIndex) => (
                                        <div key={bulletIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginTop: '5px' }}>
                                            <CheckRounded style={{ color: COLORS.orangeICB }} />
                                            <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', marginLeft: 2, textAlign: 'left' }}>
                                                {bullet}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>

                                <Grid container justifyContent="center">
                                    <Button
                                        variant="outlined"
                                        href={`${appSignUp}`}
                                        sx={{
                                            textTransform: 'none',
                                            marginTop: 2,
                                            fontWeight: 'bold',
                                            backgroundColor: COLORS.orangeICB,
                                            borderColor: lighten(COLORS.orangeICB, 0.2),
                                            borderWidth: '1px',
                                            borderStyle: 'solid',
                                            transition: 'opacity 1000ms',
                                            color: 'white',
                                            '&:hover': {
                                                backgroundColor: darken(COLORS.orangeICB, 0.15),
                                                borderColor: lighten(COLORS.orangeICB, 0.4)
                                            }
                                        }}
                                    >
                                        {t('StartFreeTrialButton00')}
                                    </Button>
                                </Grid>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grow>
        </Grid >
    );
}