import * as React from 'react';
import Layout from '../components/Layout';
import Intro from '../components/Intro';
import Features from '../components/Features';
import { Divider } from '@mui/material';
import YouthCoaches from '../components/YouthCoaches';
import ShareDocuments from '../components/ShareDocuments';
import ShareDrills from '../components/ShareDrills';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import BannerScheduleHubspot from '../components/BannerScheduleHubspot';
import Carousel from '../components/Carousel'

export default function LandingPage() {
  return (
    <Layout>
      <Intro />
      <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px' }} />
      <Carousel />
      <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', marginTop: 2 }} />
      <Features />
      <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px' }} />
      <YouthCoaches />
      <ShareDocuments />
      <ShareDrills />
      {/* <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px' }} />
      <Carousel />
      <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', marginTop: 2 }} /> */}
      <OpinionsFromUsers />
      <BannerScheduleHubspot />
    </Layout>
  );
}
