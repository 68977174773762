import React from 'react';
import { Grid, Typography, Box, Grow, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../constants/constants';
import { lighten } from '@mui/system';
import { useInView } from 'react-intersection-observer';

export default function OpinionsFromUsers() {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });

    return (
        <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '20px', md: '100px' }, paddingBottom: 10 }}>
            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '25px', md: '40px' },
                        color: '#dce4ec',
                        fontWeight: '900',
                        fontFamily: 'Roboto, sans-serif',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('OpinionsFromUsers00')}
                    </Typography>
                </Grid>
            </Grow>

            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '20px', md: '25px' },
                        marginTop: '20px',
                        color: '#98a7b0',
                        fontFamily: 'Arial',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('OpinionsFromUsers01')}
                    </Typography>

                </Grid>
            </Grow>

            {/* Feature cards */}
            <Grid container spacing={2} justifyContent="center" mt={5}>

                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 2,
                            maxWidth: '350px',
                            margin: 'auto',
                            backgroundColor: COLORS.superdarkblueICB,
                            borderRadius: 1,
                            border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`,
                            height: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <img src="/images/cristina.png" alt="Foto" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', paddingTop: 2 }}>
                                    "{t('OpinionsFromUsers02')}"
                                </Typography>
                            </div>

                            <div>
                                <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', paddingTop: 2 }} />
                                <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', paddingTop: 2 }}>
                                    Cristina, CB Sant Antoni
                                </Typography>
                            </div>

                        </Box>
                    </Grid>
                </Grow>

                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                    <Grid item xs={12} md={4} sx={{ marginTop: { xs: '40px', md: 0 } }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 2,
                            maxWidth: '350px',
                            margin: 'auto',
                            backgroundColor: COLORS.superdarkblueICB,
                            borderRadius: 1,
                            border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`,
                            height: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <img src="/images/william.png" alt="Foto" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', paddingTop: 2 }}>
                                    "{t('OpinionsFromUsers03')}"
                                </Typography>
                            </div>

                            <div>
                                <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', paddingTop: 2 }} />
                                <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', paddingTop: 2 }}>
                                    William, BK Amager
                                </Typography>
                            </div>

                        </Box>
                    </Grid>
                </Grow>

                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                    <Grid item xs={12} md={4} sx={{ marginTop: { xs: '40px', md: 0 } }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            p: 2,
                            maxWidth: '350px',
                            margin: 'auto',
                            backgroundColor: COLORS.superdarkblueICB,
                            borderRadius: 1,
                            border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`,
                            height: '100%',
                            justifyContent: 'space-between'
                        }}>
                            <div>
                                <img src="/images/peptoni.png" alt="Foto" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                                <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', paddingTop: 2 }}>
                                    "{t('OpinionsFromUsers04')}"
                                </Typography>
                            </div>

                            <div>
                                <Divider sx={{ borderColor: '#98a7b0', width: '90%', maxWidth: '1000px', margin: 'auto', height: '1px', paddingTop: 2 }} />
                                <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', paddingTop: 2 }}>
                                    Pep Toni, CB Muro
                                </Typography>
                            </div>

                        </Box>
                    </Grid>
                </Grow>

            </Grid>

        </Grid>
    );
};