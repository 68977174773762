import * as React from 'react';
import Layout from '../components/Layout';
import IframeHubspotMeeting from '../components/IframeHubspotMeeting';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import YouthCoaches from '../components/YouthCoaches';
import ShareDocuments from '../components/ShareDocuments';
import ShareDrills from '../components/ShareDrills';

export default function HubspotMeetingPage() {
  return (
    <Layout>
      <IframeHubspotMeeting />
      <OpinionsFromUsers />
      <YouthCoaches />
      <ShareDocuments />
      <ShareDrills />
    </Layout>
  );
}
