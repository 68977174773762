import * as React from 'react';
import Layout from '../components/Layout';
import { Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../constants/constants';
import { lighten, darken } from '@mui/system';

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <Layout>
      <Box sx={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
        <Typography variant="h4" sx={{ mb: 2, color: '#dce4ec' }}>
          404 - Página no encontrada
        </Typography>
        <Typography variant="subtitle1" sx={{ color: '#98a7b0' }}>
          Lo sentimos, la página que buscas no existe.
        </Typography>
        <Button
          variant="contained"
          onClick={() => navigate('/')}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            backgroundColor: COLORS.blueICB,
            borderColor: lighten(COLORS.blueICB, 0.2),
            borderWidth: '1px',
            borderStyle: 'solid',
            marginTop: 1,
            '&:hover': {
              backgroundColor: darken(COLORS.blueICB, 0.15),
              borderColor: lighten(COLORS.blueICB, 0.4)
            }
          }}
        >
          Volver al inicio
        </Button>
      </Box>
    </Layout>
  );
}