import React from 'react';
import { Button } from '@mui/material';
import { COLORS, appSignUp } from '../constants/constants';
import { darken, lighten } from '@mui/system';
import { useTranslation } from 'react-i18next';

export default function StartFreeTrialButton({ buttonVisible }) {
    const { t } = useTranslation();
    return (
        <Button
            variant='contained'
            href={`${appSignUp}`}
            sx={{
                textTransform: 'none',
                fontWeight: 'bold',
                backgroundColor: COLORS.orangeICB,
                borderColor: lighten(COLORS.orangeICB, 0.2),
                borderWidth: '1px',
                borderStyle: 'solid',
                opacity: buttonVisible ? 1 : 0,  // Controla la opacidad con el estado buttonVisible
                transition: 'opacity 1000ms',
                '&:hover': {
                    backgroundColor: darken(COLORS.orangeICB, 0.15),
                    borderColor: lighten(COLORS.orangeICB, 0.4)
                }
            }}
        >
            {t('StartFreeTrialButton00')}
        </Button>
    );
};
