import React, { useState, useEffect } from 'react';
import { Box, Link, IconButton, Typography, Grow } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS, app } from '../constants/constants';
import { lighten } from '@mui/system';
import { useTranslation } from 'react-i18next';

export default function BannerApp() {
    const [isOpen, setIsOpen] = useState(true);
    const [showBanner, setShowBanner] = useState(false);

    const { t } = useTranslation();

    const handleClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowBanner(true);
        }, 3000); // Espera 1000ms para mostrar el banner
        return () => clearTimeout(timer);
    }, []);

    if (!isOpen) return null;

    return (
        <Grow in={showBanner} timeout={1000}>
            <Box sx={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                width: '95%',
                maxWidth: { xs: '300px', sm: '375px' },
                bgcolor: COLORS.blueICB,
                boxShadow: 3,
                borderRadius: 1,
                p: 2,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                height: '10px',
                border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`,
                zIndex: 10
            }}>

                <Link
                    href="https://apps.apple.com/app/icoachbasketball-com/6478594779"
                    target="_blank"
                    rel="noopener"
                    sx={{
                        fontSize: '11px',
                        color: '#dce4ec',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    {t('BannerApp00')}
                </Link>

                <Typography sx={{ color: 'grey', fontFamily: 'Arial', fontSize: '12px', }}>
                    o
                </Typography>

                <Link
                    href={`${app}installandroid`}
                    //target="_blank"
                    rel="noopener"
                    sx={{
                        fontSize: '11px',
                        color: '#dce4ec',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    {t('BannerApp01')}
                </Link>

                <IconButton onClick={handleClose}>
                    <CloseIcon sx={{ color: 'grey', }} />
                </IconButton>
            </Box>
        </Grow>
    );
}