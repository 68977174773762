import React from 'react';
import { Grid, Typography, Box, Grow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../constants/constants';
import { lighten } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export default function ShareDrills() {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                // Aquí podrías cambiar el estado de otro elemento o realizar alguna acción cuando inView es true
            }, 1000); // Espera 1000ms después de que inView sea true
        }
    }, [inView]);

    return (
        <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
            <Grid ref={ref} sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', paddingBottom: 10 }}>
                <Grid container spacing={2} mt={5} alignItems="center">
                    <Grid item xs={12} sm={6} order={{ xs: 2, sm: 1 }} >
                        <Typography sx={{
                            fontSize: { xs: '20px', md: '23px' },
                            color: '#33cf99',
                            textAlign: 'left',
                            fontFamily: 'Arial'
                        }}>
                            {t('ShareDrills00')}
                        </Typography>

                        <Typography sx={{
                            fontSize: { xs: '25px', md: '30px' },
                            color: '#dce4ec',
                            fontWeight: '900',
                            fontFamily: 'Roboto, sans-serif',
                            textAlign: 'left',
                            lineHeight: '1',
                        }}>
                            {t('ShareDrills01')}
                        </Typography>

                        <Typography sx={{
                            fontSize: { xs: '18px', md: '20px' },
                            marginTop: '20px',
                            color: '#98a7b0',
                            fontFamily: 'Arial',
                            textAlign: 'left',

                        }}>
                            {t('ShareDrills02')}
                        </Typography>

                        {/* Lista con iconos de verificación */}
                        <Box sx={{ mt: 3, ml: 2 }}>
                            {[t('ShareDrills03'), t('ShareDrills04'), t('ShareDrills05')].map((point, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mt: 1, }}>
                                    <CheckRoundedIcon sx={{ color: '#33cf99' }} />
                                    <Typography sx={{ ml: 1, color: '#98a7b0', fontFamily: 'Arial' }}>{point}</Typography>
                                </Box>
                            ))}
                        </Box>

                    </Grid>


                    <Grid item xs={12} sm={6} order={{ xs: 1, sm: 2 }}>
                        <img src="/images/share-drills.png" alt="Descriptive Alt Text"
                            style={{
                                width: '95%',
                                maxWidth: '400px',
                                borderRadius: '10px',
                                border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`, // Aclara el color del borde en un 20%
                            }}
                        />
                    </Grid>

                </Grid>

            </Grid>
        </Grow>
    );
};