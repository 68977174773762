import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Grow } from '@mui/material';
import { useInView } from 'react-intersection-observer';


export default function IframeHubspotMeeting() {
  const { t } = useTranslation();

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  return (
    <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '20px', md: '100px' }, paddingBottom: 10 }}>
      <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
        <Grid container justifyContent="center" ref={ref}>
          <Typography sx={{
            fontSize: { xs: '25px', md: '40px' },
            color: '#dce4ec',
            fontWeight: '900',
            fontFamily: 'Roboto, sans-serif',
            textAlign: 'center',
            lineHeight: '1'
          }}>
            {t('IframeHubspotMeeting00')}
          </Typography>
        </Grid>
      </Grow>

      <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
        <Grid container justifyContent="center" ref={ref}>
          <Typography sx={{
            fontSize: { xs: '20px', md: '25px' },
            marginTop: '20px',
            color: '#98a7b0',
            fontFamily: 'Arial',
            textAlign: 'center',
            lineHeight: '1'
          }}>
            {t('IframeHubspotMeeting01')}
          </Typography>

        </Grid>

      </Grow>

      <Box mt={2}>
        <iframe
          src="https://meetings-eu1.hubspot.com/gabriel-roman-bennassar?embed=true"
          style={{ width: '100%', height: '700px', border: '0' }}
          title={t('IframeHubspotMeeting02')} // Título descriptivo para el iframe
        ></iframe>
      </Box>

    </Grid>
  );
}