import { Fragment } from 'react';
import Marquee from 'react-fast-marquee';
import { FONT_FAMILY } from '../constants/constants';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../constants/constants';
import { Grow } from '@mui/material';
import { useInView } from 'react-intersection-observer';

let carouselImages = [
    //'andorra.white.png',
    'bka.white.png',
    'cbmuro.white.png',
    'cancanto.white.png',
    'cbzamora.white.png',
    'realejos.white.png',
    'santantoni.white.png',
    'sapobla.white.png',
    'tecla.white.png',
    'paterna.white.png',
    'escastell.white.png',
    'manises.white.png',

    //'andorra.white.png',
    'bka.white.png',
    'cbmuro.white.png',
    'cancanto.white.png',
    'cbzamora.white.png',
    'realejos.white.png',
    'santantoni.white.png',
    'sapobla.white.png',
    'tecla.white.png',
    'paterna.white.png',
    'escastell.white.png',
    'manises.white.png',

    //'andorra.white.png',
    'bka.white.png',
    'cbmuro.white.png',
    'cancanto.white.png',
    'cbzamora.white.png',
    'realejos.white.png',
    'santantoni.white.png',
    'sapobla.white.png',
    'tecla.white.png',
    'paterna.white.png',
    'escastell.white.png',
    'manises.white.png',
];

export default function Carousel({ color1 = COLORS.whiteICB, color2 = COLORS.orangeICB, color3 = COLORS.orangeICB }) {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5
    });

    return (
        <div style={{
            width: '100%',
            overflow: 'hidden',
            paddingTop: 20,
            paddingBottom: 20,
        }}>
            {/* Texto encima del carrusel */}
            <div style={{ textAlign: 'center', fontFamily: FONT_FAMILY }}>
                <h4 style={{ color: COLORS.whiteICB }}>{t('Carousel00')}</h4>
            </div>

            <div style={{ height: '80px', marginTop: 40 }} ref={ref}>
                <Marquee speed={60}>
                    {
                        carouselImages.map((item, index) => (
                            <Fragment key={index}>
                                <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={1000}>
                                    {/* Añadir prop 'alt' a los elementos img */}
                                    <img src={`/carousel/${item}`} alt="" height={80} width={'auto'} style={{ marginRight: '200px', marginBottom: '30px' }} />
                                </Grow>
                            </Fragment>
                        ))
                    }
                </Marquee>
            </div>
        </div>
    );
};