import React from 'react';
import { Grid, Typography, Box, Grow, Icon, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../constants/constants';
import { lighten } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import DirectionsRunRoundedIcon from '@mui/icons-material/DirectionsRunRounded';

export default function Features() {
    const { t } = useTranslation();
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });

    return (
        <Grid sx={{ width: '95%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '20px', md: '100px' }, paddingBottom: 10 }}>
            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2000}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '25px', md: '40px' },
                        color: '#dce4ec',
                        fontWeight: '900',
                        fontFamily: 'Roboto, sans-serif',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('Features00')}
                    </Typography>
                </Grid>
            </Grow>

            <Grow in={inView} style={{ transformOrigin: '0 0 0' }} timeout={2500}>
                <Grid container justifyContent="center" ref={ref}>
                    <Typography sx={{
                        fontSize: { xs: '20px', md: '25px' },
                        marginTop: '20px',
                        color: '#98a7b0',
                        fontFamily: 'Arial',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('Features01')}
                    </Typography>
                    <Typography sx={{
                        fontSize: { xs: '20px', md: '25px' },
                        color: '#98a7b0',
                        fontFamily: 'Arial',
                        textAlign: 'center',
                        lineHeight: '1'
                    }}>
                        {t('Features02')}
                    </Typography>
                </Grid>
            </Grow>

            {/* Feature cards */}
            <Grid container spacing={2} justifyContent="center" mt={5}>

                <Fade in={inView} style={{ transitionDelay: '500ms' }} timeout={1500}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, maxWidth: '350px', margin: 'auto' }}>
                            <Icon sx={{
                                color: '#dce4ec',
                                borderRadius: '50%',
                                backgroundColor: COLORS.orangeICB,
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '45px', // Asegura un tamaño fijo para el círculo
                                height: '45px',
                                border: `3px solid ${lighten(COLORS.orangeICB, 0.2)}`, // Aclara el color del borde en un 20%
                            }}>
                                <HourglassBottomRoundedIcon style={{ fontSize: '35px' }} />
                            </Icon>
                            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: '#dce4ec' }}>
                                {t('Features03')}
                            </Typography>
                            <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', }}>
                                {t('Features04')}
                            </Typography>
                        </Box>
                    </Grid>
                </Fade>

                <Fade in={inView} style={{ transitionDelay: '1000ms' }} timeout={1500}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, maxWidth: '350px', margin: 'auto' }}>
                            <Icon sx={{
                                color: '#dce4ec',
                                borderRadius: '50%',
                                backgroundColor: COLORS.orangeICB,
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '45px', // Asegura un tamaño fijo para el círculo
                                height: '45px',
                                border: `3px solid ${lighten(COLORS.orangeICB, 0.2)}`, // Aclara el color del borde en un 20%
                            }}>
                                <DirectionsRunRoundedIcon style={{ fontSize: '35px' }} />
                            </Icon>
                            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: '#dce4ec' }}>
                                {t('Features05')}
                            </Typography>
                            <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', }}>
                                {t('Features06')}
                            </Typography>
                        </Box>
                    </Grid>
                </Fade>

                <Fade in={inView} style={{ transitionDelay: '1500ms' }} timeout={1500}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2, maxWidth: '350px', margin: 'auto' }}>
                            <Icon sx={{
                                color: '#dce4ec',
                                borderRadius: '50%',
                                backgroundColor: COLORS.orangeICB,
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '45px', // Asegura un tamaño fijo para el círculo
                                height: '45px',
                                border: `3px solid ${lighten(COLORS.orangeICB, 0.2)}`, // Aclara el color del borde en un 20%
                            }}>
                                <ElectricBoltIcon style={{ fontSize: '35px' }} />
                            </Icon>
                            <Typography variant="h6" sx={{ mt: 1, mb: 1, color: '#dce4ec' }}>
                                {t('Features07')}
                            </Typography>
                            <Typography sx={{ color: '#98a7b0', fontFamily: 'Arial', }}>
                                {t('Features08')}
                            </Typography>
                        </Box>
                    </Grid>
                </Fade>

            </Grid>

        </Grid>
    );
};