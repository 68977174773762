import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Button} from '@mui/material';
import { COLORS } from '../constants/constants';
import { lighten, darken } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export default function BannerScheduleHubspot() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid sx={{ width: '90%', maxWidth: '1000px', margin: 'auto', marginTop: { xs: '20px', md: '100px' }, paddingBottom: 10 }}>
      <Box sx={{ backgroundColor: COLORS.blueICB, border: `1px solid ${lighten(COLORS.blueICB, 0.2)}`, borderRadius: 1 }}>
        <Grid container padding={5}>
          <Grid item xs={12} sm={8}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '20px' },
              color: '#dce4ec',
              fontWeight: '900',
              fontFamily: 'Roboto, sans-serif',
              textAlign: { xs: 'center', md: 'left' },
              lineHeight: '1'
            }}>
              {t('BannerScheduleHubspot00')}
            </Typography>
            <Typography sx={{
              fontSize: { xs: '15px', md: '18px' },
              color: '#98a7b0',
              fontFamily: 'Arial',
              textAlign: { xs: 'center', md: 'left' },
              lineHeight: '1',
              marginTop: 1
            }}>
              {t('BannerScheduleHubspot01')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container alignItems="center" justifyContent="center">
            <Button
              onClick={() => {
                navigate('/meeting');
                window.scrollTo(0, 0);
              }}
              variant='contained'
              sx={{
                textTransform: 'none',
                marginTop: { xs: 3, md: 0 },
                fontWeight: 'bold',
                backgroundColor: COLORS.orangeICB,
                borderColor: lighten(COLORS.orangeICB, 0.2),
                borderWidth: '1px',
                borderStyle: 'solid',
                transition: 'opacity 1000ms',
                '&:hover': {
                  backgroundColor: darken(COLORS.orangeICB, 0.15),
                  borderColor: lighten(COLORS.orangeICB, 0.4)
                }
              }}
            >
              {t('BannerScheduleHubspot02')}
            </Button>
          </Grid>
        </Grid>
      </Box>

    </Grid>
  );
}