import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { COLORS, app, appSignUp } from '../constants/constants';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Button, Hidden } from '@mui/material';
import { darken, lighten } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import LanguageSwitcher from './LanguageSwitcher';

export default function Header() {
    const navigate = useNavigate();
    const { t } = useTranslation()

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.5  // Ajusta según necesidad para cuándo debe activarse la animación
    });



    return (
        <Box sx={{}}>
            <Box sx={{ flexGrow: 1, maxWidth: '1000px', margin: 'auto' }} ref={ref}>
                <AppBar position="static" elevation={0} sx={{ backgroundColor: inView ? 'transparent' : 'transparent' }}>
                    <Grid container alignItems="center" justifyContent="space-between" sx={{ padding: '5px' }}>

                        <Grid item>
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    fontWeight: 800,
                                    fontFamily: 'Arial',
                                    fontSize: '15px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => navigate("/")}
                            >
                                <Link to="/">
                                    <img src="/images/icbWhite.svg" alt="iCoachBasketball" style={{ height: 37, marginRight: 10, paddingTop: 10 }} />
                                </Link>

                                <Hidden smDown>
                                    iCoachBasketball
                                </Hidden>

                            </Typography>
                        </Grid>
                        <Grid item>

                        </Grid>
                        <Grid item edge="end" style={{ display: 'flex', alignItems: 'center' }}>
                            <Button
                                variant='standard'
                                href={`${app}`}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    fontSize: { xs: '12px', md: '15px' }
                                }}

                            >
                                {t('Header00')}
                            </Button>
                            <Button
                                variant='contained'
                                href={`${appSignUp}`}
                                sx={{
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    backgroundColor: COLORS.blueICB,
                                    borderColor: lighten(COLORS.blueICB, 0.2),
                                    borderWidth: '1px',
                                    fontSize: { xs: '12px', md: '15px' },
                                    borderStyle: 'solid',
                                    '&:hover': {
                                        backgroundColor: darken(COLORS.blueICB, 0.15),
                                        borderColor: lighten(COLORS.blueICB, 0.4)
                                    }
                                }}
                            >
                                {t('Header01')}
                            </Button>
                            <LanguageSwitcher />
                        </Grid>
                    </Grid>
                </AppBar>
            </Box>
        </Box>
    );
}