import * as React from 'react';
import Layout from '../components/Layout';
import PricingCards from '../components/PricingCards';
import OpinionsFromUsers from '../components/OpinionsFromUsers';
import BannerScheduleHubspot from '../components/BannerScheduleHubspot';

export default function PricingPage() {
  return (
    <Layout>
      <PricingCards />
      <OpinionsFromUsers />
      <BannerScheduleHubspot />
    </Layout>
  );
}